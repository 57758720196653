import React from "react";
import Img from "gatsby-image";
import {graphql} from "gatsby";
import {PositiveCard, NegativeCard} from "../components/sentUpdate";
import Layout from "../templates/layout";

export default class Contact extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            fName:'',
            lName:'',
            pNum:'',
            zip:'',
            emailAddress:'',
            message:'',
            successful:0,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e){
        this.setState({[e.target.name]:e.target.value})
    }

    handleSubmit(e){
        

        if(this.state.fName === '' || this.state.lName === '' || this.state.pNum === '' || this.state.zip === '' || this.state.emailAddress === '' || this.state.message === ''){
            
            e.preventDefault();
            this.setState({successful:-1})
        }else{
             
            
            this.setState({successful:1})
        }
    }

    render(){
        const data = this.props.data.allContentfulContactMePage.nodes[0];
        const banner = data.banner;
        const emails = data.emails;
        const linkedIn = data.linkedIn;

        let submitCard = null;

        if(this.state.successful !== 0){
            if(this.state.successful===1){
                submitCard= <PositiveCard msg="Thank you! I will get back to you within 24 hours!"/>
            }else{
                submitCard=<NegativeCard/>
            }
        }
        return(
            <Layout title="Contact" des="Feel free to contact me via email; I am so commited to speaking with you, you can email me right here!">
                <div className="font-body">
                    <div className="bg-blue-900 pb-5 md:pb-10 mb-10 relative">
                        <Img fluid={banner.sizes} alt="Banner"/>
                        <h1 className="absolute text-2xl md:text-4xl lg:text-5xl text-white text-bold italic words-xs lg:words-md">Contact Me</h1>
                    </div>

                    <div className="mb-10 bg-gray-200 rounded max-w-xs  md:max-w-2xl lg:max-w-4xl mx-auto pt-4">
                        <div className="text-center mb-5 font-bold">
                            Email me yourself
                        </div>
                        <div className="flex flex-wrap justify-between text-center mx-auto md:flex-no-wrap">
                            <div className="w-full block mb-4 mx-2 lg:mx-10">
                                <p className="font-bold">Business Email</p>
                                <p><a href={`mailto:${emails[0]}`}>{emails[0]}</a></p>
                            </div>
                            <div className="w-full block mb-4">
                                <p className="font-bold">Already with Momentum Financial?</p>
                                <p><a href={`mailto:${emails[1]}`}>{emails[1]}</a></p>
                            </div>
                            <div className="w-full block mb-4">
                                <p className="font-bold">Get connected</p>
                                <p><a target = "_blank" href={linkedIn}>LinkedIn</a></p>
                            </div>
                        </div>
                    </div>

                    <div className="w-full max-w-xs sm:max-w-md md:max-w-lg lg:max-w-2xl mx-auto">
                        <div className="text-center text-gray-700 font-bold mb-6">
                            Email Me Right Here (Easiest way to contact me)
                        </div>

                        <iframe width="0" height="0" border="0" name="dummyframe" id="dummyframe" title="dummyframe"></iframe>
                        
                        <form className="simple-form" onSubmit={this.handleSubmit} action="https://yeety.prod.with-datafire.io/contact" method="post" target="dummyframe">
                            <div className="flex flex-wrap -mx-3 mb-10">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                        FirstName
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="fName" type="text" placeholder="Jane" value={this.state.fName} onChange={this.handleChange} required/>
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                        Last Name
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="lName" type="text" placeholder="Doe" value={this.state.lName} onChange={this.handleChange} required/>                        
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-between mb-5">
                                <div>
                                    <label className="block uppercase w-full tracking-wide text-gray-700 text-xs font-bold mb-2">
                                        Phone
                                    </label>
                                    <input className="appearance-none w-64 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="pNum" type="text" placeholder="(555)-555-555" value={this.state.pNum} onChange={this.handleChange} required/>
                        
                                </div>
                                <div>
                                    <label className="block uppercase w-full tracking-wide text-gray-700 text-xs font-bold mb-2 mt-5 sm:mt-0">
                                        Zip Code
                                    </label>
                                    <input className="appearance-none w-24 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="zip" type="text" placeholder="zip code" value={this.state.zip} onChange={this.handleChange} required/>
                                </div>
                            </div>
                            <div className="w-full mb-5">                    
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Email
                                </label>
                                <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="emailAddress" type="email" placeholder="Email Address" value={this.state.emailAddress} onChange={this.handleChange} required/>                    
                            </div>
                            <div className="w full mb-5">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Message
                                </label>
                                <textarea className="appearance-none text-base w-full h-32 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="message" type="text" placeholder="Description" value={this.state.message} onChange={this.handleChange} required/>
                            </div>
                            <div className="w full mb-10">
                                <button className="bg-blue-600 rounded block py-2 px-4 text-white font-bold border-b-4 border-blue-700 hover:bg-blue-500" type="submit">Send Email</button>
                            </div>
                        </form>
                    </div>
                    <div>
                        {submitCard}
                    </div>
                </div>
            </Layout>
        )
    }
    
}

export const query = graphql`
    query{
        allContentfulContactMePage {
            nodes {
              banner {
                sizes(maxWidth: 1700, quality: 100) {
                    ...GatsbyContentfulSizes
                }
              }
              emails
              linkedIn
            }
          }
    }
`